import React from "react";
import logo from "../assets/chartit-logo.png";
import birds from "../assets/birds1.gif";
import Slide from "../components/Slide";

const Home = () => {
  const today = new Date();
  return (
    <body>
      <div className="outer-wrapper">
        <div className="wrapper">
          <div className="slide">
            <div id="landingText">
              <div id="logoContainer">
                <img className="birdsOne" src={birds}></img>
                <img className="birdsTwo" src={birds}></img>
                <img className="birdsThree" src={birds}></img>
                <img className="logo" src={logo}></img>
              </div>
              <h3>
                Stock, Crypto, and Forex charts programatically rendered as
                landscape art using p5.js.
              </h3>
              <h3>
                Inspired in part by{" "}
                <a href="https://www.stoxart.com/" target="_blank">
                  Stoxart by Gladys Estolas
                </a>
              </h3>
              <h3 style={{ marginTop: "10%" }}>Coming Soon...</h3>
              <p>Scroll to see examples of what's in store! ➡️</p>
            </div>
          </div>
          <Slide
            asset={"META"}
            exchange={"NASDAQ"}
            time={"2012-05-01 to 2022-07-13"}
          />
          <Slide
            asset={"BTC"}
            exchange={"BITSTAMP"}
            time={"2011-08-01 to 2022-07-11"}
          />
          <Slide
            asset={"EUR"}
            exchange={"FXCM"}
            time={"1993-05-03 to 2022-07-14"}
          />
          <footer>
            {" "}
            <small>
              Copyright &copy; {today.getFullYear()}, Logan Scheid, All Rights
              Reserved
            </small>{" "}
          </footer>
        </div>
      </div>
    </body>
  );
};

export default Home;
