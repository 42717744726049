import React from "react";
import BTCChart from "../assets/btc_chart.png";
import BTCLandscape from "../assets/btc_landscape.png";
import MetaChart from "../assets/meta_chart.png";
import MetaLandscape from "../assets/meta_landscape.png";
import EURChart from "../assets/eurusd_chart.png";
import EURLandscape from "../assets/eurusd_landscape.png";

const Slide = ({ asset, exchange, time }) => {
  return (
    <div className="slide">
      <div className="imgWrapper">
        <figure>
          <h3>
            <hr />
            {asset}/USD Monthly
            <br />({exchange})
            <br />
            {time}
            <hr />
          </h3>
          <p>Hover or tap below.</p>
          <div className="imgArea">
            <img
              className="imgBack"
              src={
                asset === "BTC"
                  ? BTCLandscape
                  : asset === "META"
                  ? MetaLandscape
                  : EURLandscape
              }
            ></img>
            <img
              className="imgFront"
              src={
                asset === "BTC"
                  ? BTCChart
                  : asset === "META"
                  ? MetaChart
                  : EURChart
              }
            ></img>
          </div>
        </figure>
      </div>
    </div>
  );
};

export default Slide;
